.after .title,
.after .logo,
.after .nav,
.after #message-container {
    transition-property: transform, opacity;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;
}

.before .logo,
.before .nav
{
    transform: translate3D(25px, 150px, 0) scale(2);
}

.before .title,
.before #message-container {
    transform: translate3D(0, 200px, 0);
}

.nav {
    border: 0;
    opacity: 1;
}

.before .nav,
.before #message-container {
    opacity: 0;
}

.after #message-container {
    opacity: 1;
}