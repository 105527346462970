@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  /**/
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--c-scroll-thumb);
  border-radius: 0.25em;
}

:root {
  --c-bg: #f6f8fa;
  --c-fg: #1a1a1a;
  --c-scroll-thumb: #b9b9b9;
  --c-text-slate: rgb(148, 163, 184);
}

html {
  font-family: system-ui, sans-serif;
  background-color: var(--c-bg);
  color: var(--c-fg);
}

html.dark {
  --c-bg: #16161a;
  --c-scroll-thumb: #4c4c4c;
  --c-text-slate: rgb(148, 163, 184);
}


main {
  max-width: 72ch;
  margin: 0 auto;
  padding: 8em 2em;
}

@media (min-width: 1024px) {
  main {
    max-width: 90ch;
  }
}

@media (max-width: 768px) {
  main {
    max-width: 72ch;
    padding-top: 2em;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-y: scroll;
}

:root {
  overflow-y: auto;
  overflow-x: hidden;
}

:root body {
  position: absolute;
}

body {
  width: 100vw;
  overflow: hidden;
}

.dark .dark\:bg-slate-200 {
  background-color: #2d3748;
}

.dark .dark\:text-slate
{
  color: #cbd5e0;
}
.dark .dark\:text-slate-500 {
  color: #94a3b8;
}
